import { render, staticRenderFns } from "./violations.vue?vue&type=template&id=4bb6f6ea&scoped=true&"
import script from "./violations.vue?vue&type=script&lang=js&"
export * from "./violations.vue?vue&type=script&lang=js&"
import style0 from "./violations.vue?vue&type=style&index=0&id=4bb6f6ea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bb6f6ea",
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QBtn,QTr,QTd,QTh,QBadge,QBtnDropdown,QList,QItem,QItemSection,QItemLabel});qInstall(component, 'directives', {ClosePopup});
