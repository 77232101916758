<template>
    <q-dialog full-width v-model="openDialog">
        <q-card>
            <q-card-section class="row items-center">
                <div class="text-h6">Выбрать нарушения</div>
                <q-space />
                <q-btn icon="close" flat round dense @click="$emit('close')" />
            </q-card-section>
            
                    <q-table
                        :data="data"
                        :columns="columns"
                        row-key="number"
                        no-data-label="Нет данных"
                        no-results-label="Нет данных"
                        :rows-per-page-options="[20]"
                        table-header-class="bg-grey-2"
                        selection="multiple"
                        :selected.sync="selected"
                        >
                     <template v-slot:body-cell-type="props">
                        <q-td :props="props">
                            <q-badge :color="typeColor(props.value)" :label="props.value.label" style="padding:4px 8px" class="text-capitalize"/>
                        </q-td>
                    </template>
                    </q-table>
            
                    <q-card-actions>
                        <q-btn class="bg-primary text-white" label="Ok" @click.stop="onSubmit"/>
                    </q-card-actions>
            </q-card>
        </q-dialog>
</template>

<script>

export default {
    props:['openDialog'],
    created(){
       
    },
    data () {
        return {
             columns: [
                { name: 'number',required: true,label: '#',align: 'left',field: 'number', sortable: true},
                { name: 'date', required: true, label: 'Дата', align: 'left', field: 'date', sortable: true},
                { name: 'author', align: 'left', label: 'Автор', field: row=>row.author.label, sortable: true },
                { name: 'violator', align: 'left', label: 'Нарушитель', field: row=>row.violator.label, sortable: true },
                { name: 'type', align: 'center', label: 'Тип нарушения', field: 'type', sortable: true },
                // { name: 'case',  align: 'left', label: 'Дело №', field: 'case' },
                // { name: 'status',  align: 'left', label: 'Статус', field: 'status', sortable: true},
            ],
            selected:[]
        }
    },
    computed: {
        data(){
            // console.log(this.$store.state.cases.violationsByViolator)
            return this.$store.state.cases.violationsByViolator
        },
        statusColor(){
            return val => val.id === 1 ? 'secondary' : 'red-5'
        },
        typeColor(){
            return val => val.id === 1 ? 'secondary' : 'red-4'
        },
    },
    methods:{
        onDelete(){
            this.$store.commit('violations/DELETE_OBJECT', {'objectId': this.data.updateId})
            this.$emit('close')
        },
        onSubmit(){
            if(this.selected.length > 0){
                this.$store.commit('cases/SET_VIOLATIONS', this.selected)
            }
            this.$emit('close')
        },
        
       
    }
    
}
</script>