<template>
    <div>
        <q-card flat bordered class="my-card">
        <q-card-section>
        <div class="column q-col-gutter-y-md">
            <div class="row q-col-gutter-md">
                <q-select outlined v-model="form.type" :options="types" label="Тип дела" class="col-grow col-md-6" dense />
                <q-input outlined dense v-model="form.date" mask="##.##.####" label="Дата" class="col-grow col-md-6">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="form.date" mask="DD.MM.YYYY">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Ok" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
            </div>
             <div class="row q-col-gutter-md">
                <q-select outlined v-model="form.region" :options="regions" label="Регион" class="col-grow col-md-6" @input="form.city = null" dense />
                <q-select outlined v-model="form.city" :options="citys" label="Город" class="col-grow col-md-6" dense />
            </div>
        </div>
        </q-card-section>
        <!-- <q-separator /> -->
        <q-card-actions>
            <q-btn unelevated color="primary" @click="onSubmit()">Сохранить</q-btn>
        </q-card-actions>
        </q-card>
    </div>
</template>

<script>


export default {
    created(){
        this.$store.dispatch('regions/fetchRegions')
    },
    data () {
        return {
            form:{
                type:this.$store.state.cases.item.type ||'',
                date:this.$store.state.cases.item.date ||'',
                city: this.$store.state.cases.item.city || '',
                region: this.$store.state.cases.item.region || '',
            },
            
            addToTimeline: false,
        }
    },
    computed:{
        info(){
            return ''
        },
        regions(){
            return this.$store.getters['regions/list']
        },
        types(){
            return this.$store.state.cases.types
        },
        citys(){
            if(this.form.region.id){
                return this.$store.getters['regions/citys'](this.form.region.id)
            }
            return []
        }
    },
    methods: {
        onSubmit(){
            this.$store.dispatch('cases/setInfo', this.form)
            this.$q.notify({
                position: 'top',
                color: 'green-5',
                textColor: 'white',
                icon: 'check',
                message: 'Сохранено'
            })
        }
    }
}
</script>