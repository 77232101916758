<template>
    <div class="q-pa-md">
        <q-card class="my-card">
            <q-card-section>
                <div class="text-h6" >{{title}}</div>
            </q-card-section>
            <q-tabs
                v-model="tab"
                inline-label
                align="left"
                class="text-primary bg-grey-2"
            >
                <q-route-tab label="Инфо" name="info" exact replace :to="{ params: { tab: 'info' }}"/>
                <q-route-tab label="Нарушения" name="objects" exact replace :to="{ params: { tab: 'objects' }}"/>
                <q-route-tab label="Претензия" name="claim" exact replace :to="{ params: { tab: 'claim' }}"/>
                <q-route-tab v-if="showCourtTab" name="court" label="В суде" exact replace :to="{ params: { tab: 'court' }}"/>
            </q-tabs>
            <q-tab-panels v-model="tab" animated>
                <q-tab-panel name="info">
                    <div class="column">
                        <div class="row q-col-gutter-x-md">
                            <div class="col-grow col-md-6">
                                <cases-info/>
                            </div>
                            <div class="col-grow col-md-6">
                                <cases-status/>
                            </div>
                        </div>
                    </div>        
                </q-tab-panel>
                <q-tab-panel name="objects">
                    <violations/>
                </q-tab-panel>
                <q-tab-panel name="claim">
                    claim
                </q-tab-panel>
                <q-tab-panel name="court">
                    court
                </q-tab-panel>
            </q-tab-panels>
        </q-card>
    </div>
</template>

<script>
import CasesInfo from '@/components/cases/info.vue'
import CasesStatus from '@/components/cases/status.vue'
import Violations from '@/components/cases/violations.vue'
export default {
    components: { CasesInfo, CasesStatus, Violations },
    created(){
        if(this.$route.params.id){
            this.$store.dispatch('cases/fetchItemById', this.$route.params.id)
        }
        if(this.$route.params.tab){
            this.tab = this.$route.params.tab
        }
    },
    data () {
        return {
            tab: 'info',
            data: []
        }
    },
    computed:{
        title(){
            if(this.$route.params.id){
                return "Дело №"+this.$route.params.id
            }
            return 'Создать'
        },
        showCourtTab(){
            return this.$store.state.cases.item.type.id === 2
        }
    }
}
</script>