<template>
    <div>
        <div class="q-gutter-y-md column">
             <div class="q-px-lg q-pb-md">
                  <!-- <q-btn dense flat icon="add" color="green-9" label="Добавить" @click="addToTimeline = true" /> -->
                <q-timeline color="primary">
                    <q-timeline-entry
                        
                        icon="add"
                        @click="addToTimeline = true"
                        color="secondary"
                        style="cursor:pointer"
                    >
                        <template v-slot:default>
                        <div></div>
                        </template>
                    </q-timeline-entry>
                    <q-timeline-entry v-for="(status, index) in statusLine" :key="index">
                        <template v-slot:subtitle>
                             {{status.dateTime}}<strong style="margin-left:1em; opacity: 1">{{status.title}}</strong>
                        </template>
                        <div v-if="status.info.length > 0">
                            <strong style="margin-right:1em">Инфо:</strong>
                            {{status.info}}
                        </div>
                    </q-timeline-entry>
                </q-timeline>
             </div>
        </div>
        <q-dialog v-model="addToTimeline">
            <q-card  style="width: 700px; max-width: 80vw;">
                <q-card-section class="row items-center q-pb-none">
                    <div class="text-h6">Добавить статус</div>
                    <q-space />
                    <q-btn icon="close" flat round dense v-close-popup @click="resetForm" />
                </q-card-section>
                <q-form @submit="onSubmit" >
                    <q-card-section >
                        <q-select outlined v-model="form.status" :options="stats" label="Статус" class="col-grow" dense :rules="[selectRule]"/>
                        <q-input outlined v-model="form.info" label="Инфо" type="textarea" />
                    </q-card-section>
                    <q-separator />
                    <q-card-actions>
                        <q-btn type="submit" class="bg-primary text-white">Сохранить</q-btn>
                    </q-card-actions>
                </q-form>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>


export default {
    created(){
        if(this.$store.state.cases.item.type){
            this.$store.dispatch('cases/fetchStatsByType', this.$store.state.cases.item.type.id)
        }
    },
    data () {
        return {
            form: {
                status:null,
                info:'',
            },
            addToTimeline: false
        }
    },
    computed:{
        statusLine(){
            return this.$store.state.cases.item.statusLine
        },
        stats(){
            return this.$store.state.cases.stats || []
        }
    },
    methods: {
        filterAuthors(){
            return false
        },
        filterViolators(){
            return false
        },
        selectRule (val) {
            if (val === null) {
                return ''        
            }
        },
        onSubmit(){
            this.addToTimeline = false
            this.$store.dispatch('cases/setStatus', this.form)
            this.resetForm()
        },
        resetForm(){
            this.form.status = null
            this.form.info = ''
        }
    }
}
</script>